import React, { useState, useContext, useEffect } from 'react';
import Modal from '../high-order/Modal';
import configs from 'qcp-js-ui-core/configs';
import theme from '../theme';
import styled, { css, ThemeContext } from 'styled-components';
import Icon from 'react-web-vector-icons';
import { useHistory } from 'react-router-dom'
import { forgotPassword, resetPassword, confirmNewPassword } from '../../utils/cognito-session';
import Button from '../shared/Button';
import { clearNotification } from '../../store/actions/notification';
import { signIn } from '../../utils/cognito-session';
import { ReduxlessNotification } from '../shared/Notification';


const Login = () => {
  const themeContext = useContext(ThemeContext);
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [loginMessage, setLoginMessage] = useState('');
  const [loginStatus, setLoginStatus] = useState(false);
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const [showState, setShowState] = useState('login');
  //TODO: determine how we set the email domain
  //const [emailDomain, setEmailDomain] = useState(window.emailDomain);
  const emailDomain = window.emailDomain;
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [ status, setStatus ] = useState(null);
  const [ toastMessage, setToastMessage ] = useState(null);
  const navigate = useHistory();

  const onDismiss = () => { setToastMessage(null) }
  const getHomepage = () => {
    let appName = window.qcpConfig.appName != '' ? window.qcpConfig.appName : window.localAppName;
    const homepageUrls = {
        rms: '/patrolHomePageView',
        ev: '/allEvidenceView'
    }
    let found = Object.entries(homepageUrls).filter(([key, value]) => key == appName );
    return found.length ? found[0][1] : homepageUrls.rms;
  }

  useEffect(() => {
    onDismiss();
    const loginStateChannel = new BroadcastChannel("loginStateChannel");
    loginStateChannel.onmessage = (event) => {
        const message = JSON.parse(event?.data || {});
        if(message?.status) {
            setStatus(message);
        }
    };
    return () => { loginStateChannel.close() }
  }, []);

  const statusChanges = {
    SUCCESS: () => {
        setLoginStatus(status);
        if(location.pathname == '/login' && username != '' && password != '') {
            setLoginMessage('Success! Logging in...');
            setTimeout(() => { navigate.push(getHomepage()) }, 3000);
        }
    },
    CONFIRMUSER: () => {
        setShowState('confirm');
    },
    CONFIRMUSERSUCCESS: () => {
        setLoginStatus(status);
        setLoginMessage('Success! You may now login...');
        setShowState('login');
    },
    CONFIRMUSERFAIL: ({message}) => {
        onDismiss();
        setToastMessage('Failed confirming your account! Please Try Again. ' + message);
    },
    PROCESSING: () => {
        setLoginMessage('Authenticating...');
    },
    FAIL: ({message}) => {
        setLoginMessage('Login Failure! Please Try Again. ' + message);
        setTimeout(() => { setLoginMessage('') }, 10000);
    },
    FORGOTFAIL: ({message}) => {
        onDismiss();
        setToastMessage('Failed Requesting Password Reset! Please Try Again. ' + message);
        setShowState('forgot');
    },
    FORGOTSUCCESS: () => {
        onDismiss();
        setToastMessage('Succeeded Requesting Password Reset!');
        setShowState('reset');
    },
    RESETFAIL: ({message}) => {
        onDismiss();
        setToastMessage('Failed With Password Reset! Please Try Again. ' + message);
        setShowState('reset');
    },
    RESETSUCCESS: () => {
        onDismiss();
        setToastMessage('Succeeded With Password Reset!');
        setShowState('login');
    },
  };

  useEffect(() => {
    statusChanges[status?.status] && statusChanges[status.status](status);
  }, [status]);

  useEffect(() => {

    if(newPassword?.length > 3) {
        if(newPassword === newPasswordConfirm) {
            setConfirmButtonDisabled(false);
        } else {
            setConfirmButtonDisabled(true);
        }
    } else {
        setConfirmButtonDisabled(true);
    }

  }, [newPassword, newPasswordConfirm])

  const handleForgotPress = () => {
    forgotPassword(username);
  }

  const handleKeyPress = (e) => {
    if(e.key === 'Enter') {
      e.target.blur();
      handleLoginPress();
    }
  };

  const handleResetPress = () => {
    resetPassword(username, code, password);
  }

  const handleConfirmPress = () => {
    confirmNewPassword(username, password, newPassword);
  }

  const handleLoginPress = () => {
    signIn(username, password);
  };

  const handleChange = (method) => (event) => method(event?.target?.value || '');

  const countCredentials = () => {
    const emailExp = /[a-z0-9]+@[a-z]+\.[a-z]/
    if(username?.length > 5 && username.match(emailExp) && password?.length > 5) {
        setLoginButtonDisabled(false);
    } else {
        setLoginButtonDisabled(true);
    }
  }

  const onClose = () => { setShowState('login') }

  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  const showStates = {
    login:
        <Modal onClose={onClose} visible={true} size='small' title='Login'>
            {toastMessage && <ReduxlessNotification message={toastMessage} notificationStyle={'alert'} onDismiss={onDismiss}/>}
            <small>ex: user@domain.com</small>
            <InputContainer activeTheme={themeContext}>
                <Icon
                    name='user'
                    font='FontAwesome'
                    color={theme.sideBarBackground}
                    size={20}
                    />
                <input
                  name="username"
                  className="login-input"
                  placeholder='Username'
                  value={username}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange(setUsername)}
                />
            </InputContainer>
            <InputContainer>
                <Icon
                    name='key'
                    font='Entypo'
                    color={theme.sideBarBackground}
                    size={20}
                    />
                <input
                  name="password"
                  type={isPasswordHidden ? 'password' : 'text'}
                  className="login-input"
                  placeholder='Password'
                  value={password}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange(setPassword)}
                />
                <span onClick={() => setIsPasswordHidden(value => !value)}>
                <Icon
                    name={isPasswordHidden ? 'eye' : 'eye-with-line'}
                    font='Entypo'
                    color={theme.sideBarBackground}
                    size={20}
                />
                </span>
            </InputContainer>
          <hr/>
            <ForgotPassword onClick={() => setShowState('forgot')}>
                Forgot Your Password?
            </ForgotPassword>
          <LoginFooter>
            <LoginMessage>
            { loginMessage != '' &&
            <>
                <Icon
                    name={loginMessage.includes('Invalid') ? 'exclamation' : 'spinner'}
                    font='FontAwesome'
                    color={loginMessage.includes('Invalid') ? theme.red : theme.green}
                    size={20}
                />
                <span>{loginMessage}</span>
            </>
            }
            </LoginMessage>
            <LoginButton disabled={loginButtonDisabled} onClick={handleLoginPress}>Login</LoginButton>
          </LoginFooter>
        </Modal>,
    forgot:
        <Modal onClose={onClose} visible={true} size='small' title='Forgot Password'>
            {toastMessage && <ReduxlessNotification message={toastMessage} notificationStyle={'alert'} onDismiss={onDismiss}/>}
            <p>Please enter your username to reset your password.</p>
            <small>ex: user@domain.com</small>
            <InputContainer activeTheme={themeContext}>
                <Icon
                    name='user'
                    font='FontAwesome'
                    color={theme.sideBarBackground}
                    size={20}
                    />
                <input
                  name="username"
                  className="login-input"
                  placeholder='Username'
                  value={username}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange(setUsername)}
                />
            </InputContainer> 
            <Footer>
                <Button type={'primary'} onPress={() => setShowState('login')} label="Back" />
                <LoginButton disabled={loginButtonDisabled} onClick={handleForgotPress}>
                    Forgot My Password
                </LoginButton>
            </Footer>
        </Modal>,
    reset:
        <Modal onClose={onClose} visible={true} size='small' title='Reset Password'>
            {toastMessage && <ReduxlessNotification message={toastMessage} notificationStyle={'alert'} onDismiss={onDismiss}/>}
            <p>Please find your password reset code from your recovery method.</p>
            <small>ex: user@domain.com</small>
            <InputContainer activeTheme={themeContext}>
                <Icon
                    name='user'
                    font='FontAwesome'
                    color={theme.sideBarBackground}
                    size={20}
                    />
                <input
                  name="username"
                  className="login-input"
                  placeholder='Username'
                  value={username}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange(setUsername)}
                />
            </InputContainer>
            <InputContainer activeTheme={themeContext}>
                <Icon
                    name='message-circle'
                    font='FontAwesome'
                    color={theme.sideBarBackground}
                    size={20}
                    />
                <input
                  name="username"
                  className="login-input"
                  placeholder='Code'
                  value={code}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange(setCode)}
                />
            </InputContainer>
            <InputContainer>
                <Icon
                    name='key'
                    font='Entypo'
                    color={theme.sideBarBackground}
                    size={20}
                    />
                <input
                  name="password"
                  type="password"
                  className="login-input"
                  placeholder='New Password'
                  value={password}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange(setPassword)}
                />
                <span onClick={() => setIsPasswordHidden(value => !value)}>
                <Icon
                    name={isPasswordHidden ? 'eye' : 'eye-with-line'}
                    font='Entypo'
                    color={theme.sideBarBackground}
                    size={20}
                />
                </span>
            </InputContainer>
            <Footer>
                <Button type={'primary'} onPress={() => setShowState('forgot')} label="Back" />
                <LoginButton disabled={loginButtonDisabled} onClick={handleResetPress}>
                    Reset My Password
                </LoginButton>
            </Footer>
        </Modal>,
    confirm:
    <Modal onClose={onClose} visible={true} size='small' title='Change Password'>
        {toastMessage && <ReduxlessNotification message={toastMessage} notificationStyle={'alert'} onDismiss={onDismiss}/>}
        <p>Please enter a new password for your account.</p>
        <InputContainer>
            <Icon
                name='key'
                font='Entypo'
                color={theme.sideBarBackground}
                size={20}
                />
            <input
              name="password"
              type="password"
              className="login-input"
              placeholder='New Password'
              value={newPassword}
              onKeyDown={handleKeyPress}
              onChange={handleChange(setNewPassword)}
            />
        </InputContainer>
        <InputContainer>
            <Icon
                name='key'
                font='Entypo'
                color={theme.sideBarBackground}
                size={20}
                />
            <input
              name="confirm"
              type="password"
              className="login-input"
              placeholder='Confirm New Password'
              value={newPasswordConfirm}
              onKeyDown={handleKeyPress}
              onChange={handleChange(setNewPasswordConfirm)}
            />
        </InputContainer>
        <Footer>
            <Button type={'primary'} onPress={() => setShowState('login')} label="Back" />
            <LoginButton disabled={confirmButtonDisabled} onClick={handleConfirmPress}>
                Confirm New Password
            </LoginButton>
        </Footer>
    </Modal>,
  };

  return showStates[showState];
};

const ForgotPassword = styled.a`
    cursor: pointer;
    position: relative;
    top: -25px;
`

const InputContainer = styled.div`
    display: flex;
    width: 380px;
    margin-bottom: 15px;
    align-items: center;

    i {
        position: absolute;
        margin-left: 1px;
        border-radius: 3px 0 0 3px;
        background-color: #efefefaa;
        padding: 5px;
        width: 20px;
        text-align: center;
    }
    span{
        user-select: none;
        position: absolute;
        padding-bottom: 32px;

        i {
            left: 345px;
            border-radius: 0px 3px 3px 0px;
            background: white;
            scale: 0.9;
            text-align: center;
        }
        &:hover {
            cursor: pointer;
        }
    }
    
    .login-input {
        padding: 15px 0 15px 40px;
        height: 30px;
        width: 100%;
        border: ${theme.inputBorder};
        border-radius: ${theme.borderRadius}px;
        background: ${({ activeTheme }) => activeTheme?.inputBackground};
        color: ${theme.black};
        font-family: inherit;
    }
`;

const Footer = styled.div`
display: flex;
justify-content: space-between;
`;

const LoginFooter = styled.div`
display: flex;
justify-content: space-between;
place-items: end center;
`;

const LoginButton = styled.button`
background: ${theme.gradientBlueLight};
color: white;
height: 30px;
border: none;
border-radius: 4px;
letter-spacing: 1px;
align-items: center;

&:hover {
    opacity: .75;
    cursor: pointer;
}

&:disabled {
    opacity: .75;
    background: grey;
    cursor: not-allowed;
}
`;

const LoginMessage = styled.div`
align-items: center;
background: #efefefaa;
color: black;
border-radius: 4px;
padding: 4px;
letter-spacing: 1px;

span {
    margin-left: 10px;
}
`;

export default Login;
